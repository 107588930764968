import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Checkbox,
  Loading,
  Spacer,
  Text,
  Textarea,
} from "@nextui-org/react";
import {
  createPredictionRequest,
  createStreamPredictionRequest,
} from "../../../../api/playground";
import { CenteredContent } from "../ProjectMainPage";
import { toastError } from "../../../../utils/toasts";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import AddFundsModal from "../../../Finances/Modals/AddFundsModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import styles from "../styles/Playground.module.css";
import FileInput from "./FileInput";
import DownloadShare from "../components/downloadshare.tsx";

import Vector912 from "../images/Vector912.svg";
import chevronUp from "../images/chevronUp.svg";
import chevronDown from "../images/chevronDown.svg";
import lighting from "../images/lighting.svg";
import lightingButton from "../images/lightingButton.svg";
import InputInt from "../components/Input/index.jsx";
import MissingParametersAlert from "../components/MissingParametersAlert/index.tsx";
import { ERROR_RUN_BTN } from "../constans.js";
import isFormValid from "../../../../utils/formValidation.ts";
import { useQueryClient } from "@tanstack/react-query";

const requestTrashHold = 10;

export const StyledCode = styled.code`
  background-color: transparent;
  color: black;
  overflow: auto;
  white-space: wrap;
  max-width: 100%;
  display: flex;
`;

const LabelComponent = ({ name, isInstantGeneratingEnabled, isLoading }) => {
  if (isInstantGeneratingEnabled && !isLoading) {
    return (
      <Text className={styles.outputLabel}>
        {name} <Spacer x={0.5} />
      </Text>
    );
  } else if (isInstantGeneratingEnabled && isLoading) {
    return (
      <Text className={styles.outputLabel}>
        {name}
        <Loading
          type="points-opacity"
          size="sm"
          className={styles.instantGeneratingLoading}
        />
      </Text>
    );
  } else {
    return <Text className={styles.outputLabel}>{name}</Text>;
  }
};

const InstantGeneratingSwitch = ({ isEnabled, onChange }) => (
  <div className={styles.instantGeneratingContainer}>
    <Form.Check
      reverse
      type="switch"
      id="instant-generating-switch"
      label={
        <>
          <Image
            src={lighting}
            style={{ paddingRight: "5px" }}
          />
          Instant generating
        </>
      }
      checked={isEnabled}
      onChange={onChange}
    />
  </div>
);

const hasStreamingOutput = (outputModel) => {
  return Object.values(outputModel.properties).some(
    (property) => property.isStream === true
  );
};

const Playground = ({
  username,
  projectName,
  description = "",
  apiKey = "",
  inputModel,
  outputModel,
  requestUser,
  setRequestUser,
}) => {
  const [formData, setFormData] = useState({});
  const [responseData, setResponseData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(null);
  const [isInstantGeneratingEnabled, setIsInstantGeneratingEnabled] =
    useState(false);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [inferenceTime, setInferenceTime] = useState(null);
  const [lastInferenceTime, setLastInferenceTime] = useState(null);
  const [isAddFundsModalOpen, setIsAddFundsModalOpen] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const [streamDetails, setStreamDetails] = useState(null);

  const fileInputRefs = useRef({});
  const previousFormDataRef = useRef({});
  const formDataRef = useRef(formData);
  const formErrorsRef = useRef(formErrors);
  let sequenceNumberLastShow = useRef(0);
  const sequenceNumberLastSend = useRef(0);
  const [queue, setQueue] = useState([]);
  const queryClient = useQueryClient();

  // New state variables for implementing the query threshold
  const [outstandingRequests, setOutstandingRequests] = useState(0);
  const [intervalFactor, setIntervalFactor] = useState(1.5);

  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);

  useEffect(() => {
    formErrorsRef.current = formErrors;
  }, [formErrors]);

  useEffect(() => {
    if (inputModel && outputModel) {
      setInitialFormData();
      setInitialResponseData();
      setIsStreaming(hasStreamingOutput(outputModel));
    }
  }, [inputModel, outputModel]);

  const handlerSwitchingGenerationInstance = () => {
    if (isInstantGeneratingEnabled) {
      clearResults();
      previousFormDataRef.current = {};
    } else {
      setQueue([]);
      sequenceNumberLastSend.current = 0;
      sequenceNumberLastShow.current = 0;
    }
    setIsInstantGeneratingEnabled(!isInstantGeneratingEnabled);
  };

  const clearResults = () => {
    setResponseData({});
    setResponseError(null);
    setStreamDetails(null);
    setInferenceTime(null);
    setLastInferenceTime(null);
  };

  const isEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (typeof a !== "object" || typeof b !== "object") return false;

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    for (let key of keysA) {
      if (!keysB.includes(key) || !isEqual(a[key], b[key])) {
        return false;
      }
    }

    return true;
  };

  const hasFormDataChangedFunc = (prevData, currentData) => {
    if (!prevData) return true;
    for (const key in currentData) {
      if (!currentData.hasOwnProperty(key)) continue;

      const fileInput = fileInputRefs.current[key];
      if (fileInput && fileInput.isCapturing && fileInput.isCapturing()) {
        return true;
      }

      if (!isEqual(prevData[key], currentData[key])) {
        return true;
      }
    }
    return false;
  };

  const closeAddFundsModal = async () => {
    setIsAddFundsModalOpen(false);
  };

  const renderMediaContent = (name, value, type) => {
    const renderPlaceholder = () => (
      <div className={styles.sampleImage}>
        {isLoading || isInstantGeneratingEnabled ? (
          <span>Running...</span>
        ) : (
          <span>Result</span>
        )}
      </div>
    );
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return renderPlaceholder();
    }
    if (type === "image/*") {
      return value.map((base64, index) => (
        <div
          key={`${name}-${index}`}
          style={{ border: "none" }}
          className={styles.outputMediaWrapper}
        >
          <img
            src={`data:image/jpeg;base64,${base64}`}
            alt={name}
            style={{ width: "100%" }}
          />
        </div>
      ));
    }
    else if (type === "image/png") {
      return value.map((base64, index) => (
          <div
              key={`${name}-${index}`}
              style={{ border: "none" }}
              className={styles.outputMediaWrapper}
          >
            <img
                src={`data:image/png;base64,${base64}`}
                alt={name}
                style={{ width: "100%" }}
            />
          </div>
      ));
    }
    else if (type === "audio/*") {
      return value.map((base64, index) => (
        <div
          key={`${name}-${index}`}
          className={styles.outputMediaWrapper}
        >
          <audio controls>
            <source
              src={`data:audio/wav;base64,${base64}`}
              type={"audio/wav"}
            />
            Your browser does not support the audio element.
          </audio>
        </div>
      ));
    } else if (type === "video/*") {
      return value.map((base64, index) => (
        <div
          key={`${name}-${index}`}
          className={styles.outputMediaWrapper}
        >
          <video controls>
            <source
              src={`data:video/mp4;base64,${base64}`}
              type={"video/mp4"}
            />
            Your browser does not support the video element.
          </video>
        </div>
      ));
    }
  };

  const setInitialFormData = () => {
    const initialFormData = {};
    const properties = inputModel.properties;
    console.log("p", properties)
    for (const name in properties) {
      const property = properties[name];
      if (property.type === "boolean") {
        initialFormData[name] = property.default === true;
      } else {
        initialFormData[name] = property.default ?? "";
      }
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: validateValue(
          name,
          property.type,
          initialFormData[name],
          property
        ),
      }));
    }
    setFormData(initialFormData);
  };

  const setInitialResponseData = () => {
    const initialResponseData = {};
    const properties = outputModel.properties;
    for (const name in properties) {
      initialResponseData[name] = "";
    }
    setResponseData(initialResponseData);
  };

  const validateValue = (
    name,
    type,
    value,
    { minimum, maximum, minLength, maxLength, optional }
  ) => {
    console.log("t", type, "n", name)
    if (optional && (value === "" || value === null || value === undefined)) {
      return "";
    }
    if (!optional && (value === "" || value === null || value === undefined)) {
      if (type?.includes("file")) {
        const fileInput = fileInputRefs.current[name];
        if (fileInput && fileInput.isCapturing && fileInput.isCapturing()) {
          return "";
        }
      }
      if (type === "number" || type === "integer") return;
      return "This field is required.";
    }

    switch (type) {
      case "integer":
      case "number":
        if (value !== "") {
          const numberValue = Number(value);
          if (Number.isNaN(numberValue)) {
            return "This field must be a number.";
          }
          if (minimum === "" && maximum === "") return;
          if (
            minimum !== undefined &&
            minimum !== null &&
            numberValue < minimum
          ) {
            return `This field must be at least ${minimum}.`;
          }
          if (
            maximum !== undefined &&
            maximum !== null &&
            numberValue > maximum
          ) {
            return `This field must be no more than ${maximum}.`;
          }
        }
        break;
      case "string":
        if (value.trim()) {
          if (minLength !== undefined && value.length < minLength) {
            return `This field must be at least ${minLength} characters long.`;
          }
          if (maxLength !== undefined && value.length > maxLength) {
            return `This field must be no more than ${maxLength} characters long.`;
          }
        }
        break;
      case "file":
        if (value === "capturing") {
          return "";
        }
        break;
      default:
        break;
    }
    return "";
  };

  const handleStreamingResponse = async (response) => {
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let buffer = "";

    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split("\n");

        for (let i = 0; i < lines.length - 1; i++) {
          const line = lines[i].trim();
          if (line.startsWith("data: ")) {
            try {
              const jsonString = line.slice(6);
              const parsedData = JSON.parse(jsonString);
              if (parsedData.detail && parsedData.status_code) {
                throw new Error(
                  `Error ${parsedData.status_code}: ${parsedData.detail}`
                );
              }
              if (
                parsedData.output_data &&
                parsedData.output_data.detail &&
                parsedData.status
              ) {
                throw new Error(
                  `Error ${parsedData.status}: ${parsedData.output_data.detail}`
                );
              }
              if (parsedData.output_data) {
                setResponseData((prevData) => {
                  const newData = { ...prevData };
                  for (const [key, value] of Object.entries(
                    parsedData.output_data
                  )) {
                    if (Array.isArray(value)) {
                      newData[key] = [...(newData[key] || []), ...value];
                    } else {
                      newData[key] = [...(newData[key] || []), value];
                    }
                  }
                  return newData;
                });
              }
              if (parsedData.stream_details) {
                setStreamDetails(parsedData.stream_details);
              }
            } catch (error) {
              throw error;
            }
          }
        }
        buffer = lines[lines.length - 1];
      }
    } catch (error) {
      console.error("Error in stream processing:", error);
      setResponseError(error.message || "Unknown error occurred");
    }
  };

  const parseSSEResponse = (response) => {
    const lines = response.split("\n");
    for (const line of lines) {
      if (line.startsWith("data: ")) {
        const jsonString = line.slice(6);
        try {
          return JSON.parse(jsonString);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return null;
        }
      }
    }
    return null;
  };

  // const processQueue = async (sequenceNumber, data) => {
  //   setQueue((prevQueue) => [...prevQueue, { number: sequenceNumber, data }]);

  //   while (true) {
  //     const nextElement = queue.find(
  //       (el) => el.number === sequenceNumberLastShow.current + 1
  //     );

  //     if (nextElement) {
  //       setResponseData((prevData) => ({
  //         ...prevData,
  //         ...nextElement.data,
  //       }));

  //       setQueue((prevQueue) =>
  //         prevQueue.filter((el) => el.number !== nextElement.number)
  //       );
  //       sequenceNumberLastShow.current += 1;

  //       await new Promise((resolve) => setTimeout(resolve, 160));
  //     } else {
  //       break;
  //     }
  //   }
  // };

  const sendDataToApi = async (data) => {
    setIsLoading(true);

    // Increment outstandingRequests before sending request
    setOutstandingRequests((prev) => prev + 1);

    if (!isInstantGeneratingEnabled) {
      clearResults();
    }

    const updatedData = { ...data };
    await Promise.all(
      Object.entries(fileInputRefs.current).map(async ([name, fileInput]) => {
        if (
          fileInput &&
          fileInput.getCurrentFrame &&
          fileInput.isCapturing &&
          fileInput.isCapturing()
        ) {
          const currentFrame = await fileInput.getCurrentFrame();
          if (currentFrame) {
            updatedData[name] = currentFrame;
          }
        }
      })
    );
    try {
      if (isStreaming) {
        const response = await createStreamPredictionRequest(
          username,
          projectName,
          apiKey,
          data
        );
        await handleStreamingResponse(response);
      } else {
        const response = await queryClient.fetchQuery({
          queryKey: [`${data}-${Math.random(0, 1)}`],
          queryFn: () =>
            createPredictionRequest(username, projectName, apiKey, data),
        });
        const parsedResponse = parseSSEResponse(response);

        if (parsedResponse.output_data) {
          if (
            parsedResponse.output_data.status &&
            parsedResponse.output_data.status !== 200
          ) {
            setResponseError(parsedResponse.output_data.detail);
            return;
          }

          // const outputHeaders = parsedResponse.output_data.output_headers;
          // const sequenceNumber = outputHeaders
          //   ? JSON.parse(outputHeaders[0]).sequenceNumber
          //   : null;

          // if (sequenceNumber !== null) {
          //   if (sequenceNumberLastShow.current === sequenceNumber) {
          console.log("da", parsedResponse)
          setResponseData((prevData) => ({
            ...prevData,
            ...parsedResponse.output_data,
          }));
          //     sequenceNumberLastShow.current += 1;
          //   } else {
          //     await processQueue(sequenceNumber, parsedResponse.output_data);
          //   }
          // } else {
          // setResponseData((prevData) => ({
          //   ...prevData,
          //   ...parsedResponse.output_data,
          // }));
          // }
        } else if (parsedResponse.detail) {
          setResponseError(parsedResponse.detail);
        } else if (parsedResponse.status_code) {
          setResponseError(
            `Error ${parsedResponse.status_code}: ${parsedResponse.detail}`
          );
        }

        if (
          parsedResponse.inference_time !== undefined &&
          parsedResponse.inference_time !== null
        ) {
          const newInferenceTime = parseFloat(
            (parsedResponse.inference_time * 1000).toFixed(1)
          );
          setInferenceTime(newInferenceTime);
          setLastInferenceTime(newInferenceTime);
        }
      }
    } catch (error) {
      console.error("API request error:", error);
      if (error.response) {
        setResponseError("Unknown error. Try again.");
      } else {
        setResponseError("An error occurred when sending data. Try again.");
      }
    } finally {
      setIsLoading(false);
      // Decrement outstandingRequests after response is received
      setOutstandingRequests((prev) => Math.max(prev - 1, 0));
    }
  };

  const handleChange = (name, value, type, constraints = {}) => {
    let updatedValue = value;
    if (type === "boolean") {
      updatedValue = value === true;
    } else if (type === "file") {
      updatedValue = value;
    }
    let updatedFormData = { ...formData, [name]: updatedValue };
    let updatedFormErrors = {
      ...formErrors,
      [name]: validateValue(name, type, updatedValue, constraints),
    };
    setFormData(updatedFormData);
    setFormErrors(updatedFormErrors);
  };

  const sendFormData = async (formData, formErrors) => {
    if (isFormValid(formData, formErrors, inputModel, fileInputRefs.current)) {
      await sendDataToApi(formData);
    } else {
      if (!isInstantGeneratingEnabled) {
        toastError("Form is not valid", "42");
      }
    }
  };

  // useEffect to adjust intervalFactor based on outstandingRequests
  useEffect(() => {
    if (outstandingRequests > requestTrashHold) {
      setIntervalFactor(3); // Increase the factor
    } else {
      setIntervalFactor(1.5); // Reset to default
    }
  }, [outstandingRequests]);

  useEffect(() => {
    let intervalId;
    if (isInstantGeneratingEnabled) {
      const pollingFunction = async () => {
        const updatedFormData = { ...formDataRef.current };
        for (const name in fileInputRefs.current) {
          const fileInput = fileInputRefs.current[name];
          if (
            fileInput &&
            fileInput.getCurrentFrame &&
            fileInput.isCapturing &&
            fileInput.isCapturing()
          ) {
            const currentFrame = await fileInput.getCurrentFrame();
            updatedFormData[name] = currentFrame;
          }

          // updatedFormData["special_headers"] = JSON.stringify({
          //   sequenceNumber: sequenceNumberLastSend.current++,
          // });
        }

        let formDataChanged = hasFormDataChangedFunc(
          previousFormDataRef.current,
          updatedFormData
        );

        // If video capture is enabled, we assume formData is always changing
        let isVideoCapturing = Object.values(fileInputRefs.current).some(
          (fileInput) => {
            return (
              fileInput && fileInput.isCapturing && fileInput.isCapturing()
            );
          }
        );

        if (isVideoCapturing) {
          formDataChanged = true;
        }

        if (formDataChanged) {
          previousFormDataRef.current = updatedFormData;
          sendFormData(updatedFormData, formErrorsRef.current);
        }
      };

      const interval = lastInferenceTime
        ? lastInferenceTime * intervalFactor
        : 1000;
      intervalId = setInterval(pollingFunction, interval);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isInstantGeneratingEnabled, lastInferenceTime, intervalFactor]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendFormData(formData, formErrors);
  };

  const toggleOptionalFields = () => {
    setShowOptionalFields(!showOptionalFields);
  };
  return (
    <Container fluid data-testid="playground-section">
      <Row className={styles.main}>
        <Col
          lg={6}
          className={styles.state}
        >
          {description && (
            <Row>
              <Col className={styles.description}>{description}</Col>
              <Spacer y={1.5} />
            </Row>
          )}
          <Row>
            <Col>
              {inputModel &&
                Object.entries(inputModel.properties).map(
                  ([name, detail], index) => {
                    if (detail.optional && !showOptionalFields) return null;
                    const { type, title, ...constraints } = detail.type
                      ? detail
                      : detail.anyOf
                      ? detail.anyOf[0]
                      : {};

                    return (
                      <React.Fragment key={name}>
                        <div className={styles.inputContainer}>
                          <div
                            className={`
                                                    ${styles.labelContainer} 
                                                    ${
                                                      type === "integer" ||
                                                      type === "number"
                                                        ? ""
                                                        : "mb-2"
                                                    }
                                                        `}
                          >
                            <label className={styles.inputLabel}>
                              {name + (!detail.optional ? " *" : "")}
                            </label>
                            {index === 0 && (
                              <div className={styles.switchContainer}>
                                <InstantGeneratingSwitch
                                  isEnabled={isInstantGeneratingEnabled}
                                  onChange={handlerSwitchingGenerationInstance}
                                />
                              </div>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            {type === "string" && (
                              <Textarea
                                disabled={
                                  isLoading && !isInstantGeneratingEnabled
                                }
                                borderWeight={"normal"}
                                bordered={true}
                                fullWidth={true}
                                style={{ width: "100%" }}
                                value={formData[name] ?? ""}
                                helperText={formErrors[name] || ""}
                                status={formErrors[name] ? "error" : "default"}
                                onChange={(e) =>
                                  handleChange(
                                    name,
                                    e.target.value,
                                    type,
                                    detail
                                  )
                                }
                              />
                            )}
                            {(type === "integer" || type === "number") && (
                              <InputInt
                                formData={formData}
                                formErrors={formErrors}
                                handleChange={handleChange}
                                name={name}
                                isLoading={isLoading}
                                isInstantGeneratingEnabled={
                                  isInstantGeneratingEnabled
                                }
                                type={type}
                                detail={detail}
                              />
                            )}
                            {type === "boolean" && (
                              <Checkbox
                                shadow
                                disabled={
                                  isLoading && !isInstantGeneratingEnabled
                                }
                                borderWeight={"normal"}
                                fullWidth={true}
                                style={{ width: "100%", borderWidth: "2px" }}
                                size={"sm"}
                                isSelected={formData[name] === true}
                                onChange={(checked) =>
                                  handleChange(name, checked, type, detail)
                                }
                              >
                                {name + (!detail.optional ? " *" : "")}
                              </Checkbox>
                            )}
                            {type.includes("file") && (
                              <FileInput
                                name={name}
                                detail={detail}
                                handleChange={handleChange}
                                ref={(el) => (fileInputRefs.current[name] = el)}
                              />
                            )}
                          </div>
                        </div>
                        <Spacer y={1} />
                      </React.Fragment>
                    );
                  }
                )}
            </Col>
          </Row>
          <Row style={{ marginTop: "10px", marginBottom: "15px" }}>
            <Col
              onClick={toggleOptionalFields}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span className={styles.name}>Optional settings</span>
              <span>
                <Image
                  src={Vector912}
                  alt="vector"
                  style={{ paddingLeft: "5px" }}
                />
              </span>
              <span className={styles.showSetting}>
                Show {showOptionalFields ? "less" : "more"}
                <Image
                  src={showOptionalFields ? chevronUp : chevronDown}
                  alt="chevron"
                  style={{ paddingLeft: "2px" }}
                />
              </span>
            </Col>
          </Row>
          {/* Buttons */}
          <Row>
            <Col className={`${styles.btnContainer} text-center`}>
              <form
                onSubmit={handleSubmit}
                style={{ width: "100%" }}
              >
                {requestUser && requestUser.wallet > 0 ? (
                  <div className={styles.runButtonDiv}>
                    <div style={{ width: "8px" }}></div>
                    <button
                      className={`${styles.button} ${styles.buttonReset}`}
                      type="button"
                      disabled={isInstantGeneratingEnabled || isLoading}
                      onClick={setInitialFormData}
                    >
                      Reset
                    </button>
                    <button
                      className={`${styles.button} ${styles.buttonRun}`}
                      type="submit"
                      disabled={
                        isInstantGeneratingEnabled ||
                        !isFormValid(
                          formData,
                          formErrors,
                          inputModel,
                          fileInputRefs.current
                        ) ||
                        Object.values(formErrors).some((error) => error) ||
                        isLoading
                      }
                    >
                      Run{" "}
                      <Image
                        src={lightingButton}
                        alt="lightingButton"
                      />
                    </button>
                  </div>
                ) : requestUser && !apiKey ? (
                  <MissingParametersAlert
                    run
                    button={ERROR_RUN_BTN.SETTINGS.BUTTON}
                  >
                    {ERROR_RUN_BTN.SETTINGS.TEXT}
                  </MissingParametersAlert>
                ) : requestUser && requestUser.wallet >= 0 ? (
                  <MissingParametersAlert
                    run
                    button={ERROR_RUN_BTN.WALLET.BUTTON}
                  >
                    {ERROR_RUN_BTN.WALLET.TEXT}
                  </MissingParametersAlert>
                ) : (
                  <MissingParametersAlert
                    run
                    button={ERROR_RUN_BTN.LOGIN.BUTTON}
                  >
                    {ERROR_RUN_BTN.LOGIN.TEXT}
                  </MissingParametersAlert>
                )}
              </form>
            </Col>
          </Row>
        </Col>
        {/* Result column */}
        <Col className={styles.res}>
          <div className={styles.resultContainer}>
            {responseError ? (
              <div style={{ width: "100%", minHeight: "20px" }}>
                <LabelComponent
                  name={"Inference error"}
                  isInstantGeneratingEnabled={isInstantGeneratingEnabled}
                />
                <div className={styles.resultField}>
                  <ReactMarkdown>{responseError}</ReactMarkdown>
                </div>
              </div>
            ) : (
              outputModel &&
              Object.entries(outputModel.properties).map(([name, detail]) => {
                const { type, title, contentMediaType, ...constraints } =
                  detail.type ? detail : detail.anyOf ? detail.anyOf[0] : {};
                  console.log("d", detail)
                  console.log("r", responseData)
                switch (type) {
                  case "string":
                  case "integer":
                  case "number":
                  case "array":
                    if (name != "output_headers") {
                      return (
                        <div
                          key={name}
                          style={{ minHeight: "20px" }}
                        >
                          <LabelComponent
                            name={name}
                            isInstantGeneratingEnabled={
                              isInstantGeneratingEnabled
                            }
                          />
                          <div className={styles.resultField}>
                            {responseData[name] &&
                            responseData[name].length > 0 ? (
                              type === "string" ? (
                                <ReactMarkdown>
                                  {responseData[name].join("")}
                                </ReactMarkdown>
                              ) : (
                                <Text>{responseData[name].join("")}</Text>
                              )
                            ) : !isInstantGeneratingEnabled && isLoading ? (
                              <CenteredContent>
                                <Text h4>Running...</Text>
                              </CenteredContent>
                            ) : !isInstantGeneratingEnabled ? (
                              <CenteredContent>
                                <Text
                                  h4
                                  color={"gray"}
                                >
                                  Result
                                </Text>
                              </CenteredContent>
                            ) : (
                              <CenteredContent>
                                <Text
                                  h4
                                  color={"gray"}
                                >
                                  Result
                                </Text>
                              </CenteredContent>
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  case "boolean":
                    return (
                      <>
                        <LabelComponent
                          name={name}
                          isInstantGeneratingEnabled={
                            isInstantGeneratingEnabled
                          }
                        />
                        <Card>
                          <Card.Body>
                            <Text>{responseData[name] ? "true" : "false"}</Text>
                          </Card.Body>
                        </Card>
                        <Spacer y={1} />
                      </>
                    );
                  case "file":
                    return (
                      <div
                        key={name}
                        className={styles.result}
                      >
                        <LabelComponent
                          name={name}
                          isInstantGeneratingEnabled={
                            isInstantGeneratingEnabled
                          }
                          isLoading={isLoading}
                        />
                        {responseData[name] ? (
                          <>
                            {renderMediaContent(
                              name,
                              responseData[name],
                              contentMediaType
                            )}
                            <DownloadShare
                              value={responseData[name]}
                              type={contentMediaType}
                            />
                          </>
                        ) : (
                          renderMediaContent(name, null, contentMediaType)
                        )}
                      </div>
                    );
                  default:
                    return null;
                }
              })
            )}

            {streamDetails && (
              <div style={{ padding: "12px 0px 12px 0px", fontSize: "14px" }}>
                <div style={{ border: "none", padding: 0 }}>
                  <span style={{ color: "gray", lineHeight: "24px" }}>
                    Input tokens: &nbsp;
                  </span>
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    {streamDetails.input_tokens || 11}
                  </span>
                </div>
                <div style={{ border: "none", padding: 0 }}>
                  <span style={{ color: "gray", lineHeight: "24px" }}>
                    Output tokens: &nbsp;
                  </span>
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    {streamDetails.output_tokens || 15}
                  </span>
                </div>
              </div>
            )}
            {(inferenceTime || lastInferenceTime) && (
              <div style={{ padding: "12px 0px 12px 0px", fontSize: "14px" }}>
                <span style={{ color: "gray", lineHeight: "24px" }}>
                  Inference time: &nbsp;
                </span>
                <span style={{ color: "black", fontWeight: "bold" }}>
                  {inferenceTime || lastInferenceTime} ms
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Elements stripe={stripePromise}>
        <AddFundsModal
          isOpen={isAddFundsModalOpen}
          onClose={closeAddFundsModal}
          user={requestUser}
          setUser={setRequestUser}
        />
      </Elements>
    </Container>
  );
};

export default Playground;
