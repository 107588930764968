interface IForm {
  [k: string]: string | number | File;
}

interface Model {
  properties: [];
  optional: string | undefined;
  type: string;
}

const isFormValid = (
  formData: IForm,
  formErrors: IForm,
  model: Model,
  file: any
) => {
  if (!model) return false;

  const { properties } = model;

  if (!Object.values(formErrors).every((error) => !error)) {
    return false;
  }
  console.log("type", properties);

  return Object.keys(properties).every((field) => {
    const { optional, anyOf, type } = properties[field];

    console.log(anyOf);
    console.log(type);
    console.log(optional);

    if (optional) return true;

    if (!type) {
      if (anyOf.includes("file")) {
        const fileInput = file[field];
        if (fileInput && fileInput.isCapturing && fileInput.isCapturing()) {
          return true;
        }
      }

      if (anyOf.includes("number") || type?.includes("integer")) {
        if (formData[field] === "") {
          formData[field] = 0;
        }
        return true;
      }
    } else {
      if (type?.includes("file")) {
        const fileInput = file[field];
        if (fileInput && fileInput.isCapturing && fileInput.isCapturing()) {
          return true;
        }
      }

      if (type?.includes("number") || type?.includes("integer")) {
        if (formData[field] === "") {
          formData[field] = 0;
        }
        return true;
      }
    }
    return formData[field] !== undefined && formData[field] !== "";
  });
};

export default isFormValid;
